<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="$userInfo.isSupportUser"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        Test
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="tabs is-toggle is-toggle-rounded">
              <ul>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.UnRead }">
                  <a @click="tabClicked(messageStatusTypes.UnRead)">
                    <span class="icon is-small">
                      <i class="mdi mdi-email mdi-18px" />
                    </span>
                    <span>
                      <span :class="unreadOffset">Unread</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.UnRead ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.UnRead)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Read }">
                  <a @click="tabClicked(messageStatusTypes.Read)">
                    <span class="icon is-small">
                      <i class="mdi mdi-email-open mdi-18px" />
                    </span>
                    <span>
                      <span :class="readOffset">Read</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Read ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.ToSend }">
                  <a @click="tabClicked(messageStatusTypes.ToSend)">
                    <span class="icon is-small">
                      <i class="mdi mdi-send mdi-18px" />
                    </span>
                    <span>
                      <span :class="toSendOffset">To Send</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.ToSend ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.ToSend)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Sent }">
                  <a @click="tabClicked(messageStatusTypes.Sent)">
                    <span class="icon is-small">
                      <i class="mdi mdi-checkbox-marked-circle mdi-18px" />
                    </span>
                    <span>
                      <span :class="sentOffset">Sent</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Sent ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Deleted }">
                  <a @click="tabClicked(messageStatusTypes.Deleted)">
                    <span class="icon is-small">
                      <i class="mdi mdi-delete mdi-18px" />
                    </span>
                    <span>
                      <span :class="deletedOffset">Deleted</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Deleted ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
              </ul>
              <div class="tags has-addons"
                style="margin-left: auto">
                <span class="tag is-dark">Last fetched</span>
                <span v-if="!isTableLoading"
                  class="tag is-success">{{ $filters.formatDateTimeLocale(new Date(), $userInfo.locale) }}</span>
              </div>
            </div>
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.messageId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  <router-link v-if="canEditMessage"
                    :to="{ path: entity.messageId }"
                    append>
                    {{ entity.messageType }}
                  </router-link>
                  <span v-else>{{ entity.messageType }}</span>
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.qdeStatus }}
                </td>
                <td class="has-text-centered"
                  :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.ormMessageNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.regoNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.claimNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.customerName }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.uid }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.quoteNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.vehicleDescription }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ $filters.formatDateTimeLocale(entity.createdDate, $userInfo.locale) }}
                </td>
                <td class="is-content-width">
                  <div class="has-text-centered">
                    <router-link v-if="canEditMessage"
                      :to="{ path: entity.messageId }"
                      class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="View/Edit XML"
                      append>
                      <span class="icon is-medium">
                        <i class="mdi mdi-lead-pencil mdi-24px" />
                      </span>
                    </router-link>
                    <a v-if="canLoadMessage(entity.messageTypeId)"
                      class="button is-success is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Load Message"
                      @click="load(index)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-download mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canMarkMessageRead"
                      class="button is-info is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Mark as Read"
                      @click="showMessageActionConfirmModal(entity.messageId, messageStatusTypes.Read)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-email-open mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canPrintMessage(entity.messageTypeId)"
                      class="button is-black is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Print Message"
                      @click="print(entity.messageId, entity.messageTypeId)">
                      <span class="icon is-medium ">
                        <i class="mdi mdi-printer mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canMarkMessageUnread"
                      class="button is-info is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Mark as Unread"
                      @click="markMessageAsUnread(entity.messageId)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-email mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canResendMessage(entity.statusId)"
                      class="button is-link is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Resend Message"
                      @click="markMessageAsToSend(entity.messageId)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-send mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canDeleteMessage"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Delete Message"
                      @click="showMessageActionConfirmModal(entity.messageId, messageStatusTypes.Deleted)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canUndeleteMessage"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      :data-tooltip="[entity.messageDirection === 0 ? 'Restore to Read' : 'Restore to Sent']"
                      @click="unDeleteMessage(entity.messageId, entity.messageDirection)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete-restore mdi-24px" />
                      </span>
                    </a>
                  </div>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes, MessageStatusTypes } from '@/enums'
import StoreMixin from './storeMixin'
import { Columns } from './columns'
import { Steps } from '@/components/LoadQuoteSteps'
import BulmaTable from '@/components/BulmaTable'

export default {
  name: 'EstImageMessageListView',
  components: {
    AppDetailHeader,
    BulmaTable
  },
  mixins: [StoreMixin],
  props: {
    value: null
  },
  data() {
    return {
      isTableLoading: false,
      isEarlierMessageModalActive: false,
      minimalMessageNoIndex: 0,
      entitySummaries: {},
      countSummaries: null,
      totalRows: 0,
      filter: {
        quoteNo: '',
        rego: '',
        claimNo: '',
        messageTypeId: 0,
        messageStatusId: MessageStatusTypes.UnRead,
        sortColumn: Columns[Columns.length - 2].name,
        sortOrder: Columns[Columns.length - 2].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      selectedRow: null,
      isLoadQuoteModalActive: false,
      currentStep: Steps.CreateAssign.id,
      unreadOffset: 'has-badge-child-offset2',
      readOffset: 'has-badge-child-offset2',
      toSendOffset: 'has-badge-child-offset2',
      sentOffset: 'has-badge-child-offset2',
      deletedOffset: 'has-badge-child-offset2'
    }
  },
  computed: {
    messageStatusTypes() {
      return MessageStatusTypes
    },
    columns() {
      return Columns
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    async addNew() {
      this.addStoreItem()
    },
    getCount(messageStatusId) {
      if (this.countSummaries) {
        const summary = this.countSummaries.find((c) => c.statusId === messageStatusId)
        let count = 0
        if (messageStatusId === MessageStatusTypes.ToSend) {
          const failedSummary = this.countSummaries.find((c) => c.statusId === MessageStatusTypes.Failed)
          count = summary.messageCount + failedSummary.messageCount
        } else {
          count = summary.messageCount
        }
        this.setBadgeOffset(messageStatusId, count)
        return count
      }
    },
    setBadgeOffset(messageStatusId, count) {
      let offset = 'has-badge-child-offset2'
      if (count >= 10 && count <= 99) {
        offset = 'has-badge-child-offset3'
      } else if (count >= 100 && count <= 999) {
        offset = 'has-badge-child-offset4'
      } else if (count >= 1000 && count <= 9999) {
        offset = 'has-badge-child-offset5'
      } else if (count >= 10000) {
        offset = 'has-badge-child-offset6'
      }
      switch (messageStatusId) {
        case MessageStatusTypes.UnRead:
          this.unreadOffset = offset
          break
        case MessageStatusTypes.Read:
          this.readOffset = offset
          break
        case MessageStatusTypes.ToSend:
          this.toSendOffset = offset
          break
        case MessageStatusTypes.Sent:
          this.sentOffset = offset
          break
        case MessageStatusTypes.Deleted:
          this.deletedOffset = offset
          break
      }
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    }
  }
}
</script>
