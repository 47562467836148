import { mapGetters, mapActions } from 'vuex'

const moduleName = 'estimagemessages'

export default {
  computed: {
    ...mapGetters(moduleName, {
      returnRoute: 'returnRoute',
      snapshots: 'snapshots',
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      snapshotDiff: 'snapshotDiff',
      messageSummary: 'messageSummary'
    })
  },
  methods: {
    ...mapActions(moduleName, ['getStoreItem', 'addStoreItem', 'saveSnapshot', 'clearSnapshots', 'setMessageSummary', 'clearMessageSummary'])
  }
}